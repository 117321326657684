import request from 'utils/request';

const api = {
  SUB_LEVEL_LIST: '/api/affiliate/subLevel/list',
  SUB_LEVEL_ADD_OR_UPDATE: '/api/affiliate/subLevel/addOrUpdate',
  SUB_LEVEL_DELETE: '/api/affiliate/subLevel/delete',
};
export function getSubLevelList(query) {
  return request({
    url: api.SUB_LEVEL_LIST,
    method: 'get',
    params: query,
  });
}
export function addOrUpdateSubLevel(data) {
  return request({
    url: api.SUB_LEVEL_ADD_OR_UPDATE,
    method: 'post',
    data,
  });
}
export function deleteSubLevel(ids) {
  return request({
    url: api.SUB_LEVEL_DELETE,
    method: 'delete',
    params: {
      ids,
    },
  });
}
